import { render, staticRenderFns } from "./email-lists.vue?vue&type=template&id=a95ed2f0&scoped=true"
import script from "./email-lists.vue?vue&type=script&lang=js"
export * from "./email-lists.vue?vue&type=script&lang=js"
import style0 from "./email-lists.vue?vue&type=style&index=0&id=a95ed2f0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a95ed2f0",
  null
  
)

export default component.exports